<template>
  <div>
    <ItemListTable :fixedItemTypeId="3" />
  </div>
</template>

<script>
import ItemListTable from "@/modules/item/components/ItemListTable.vue";

export default {
  name: "ConsumablesList",
  data() {
    return {};
  },
  components: {
    ItemListTable
  }
};
</script>
